@import '../../../style/App.scss';


.contact__info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

@media screen and (max-width: 990px) {
  .contact__info {
    flex-direction: column;
  }
}