@import '../../style/App.scss';

.footer {
  background-color: $black-color;
  width: 100%;
  padding: 20px;
  height: auto;
  display: flex;
  justify-content: center;

  .wrapper{
    width: 100%;

    .row{
      display: flex;
      justify-content: center;
    }
  }


  .footer-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    p {
      color: $white-color;
      font-size: 1.1rem;
      margin: 10px auto;
    }
  }

  .social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    
    a {
      color: $white-color;
      font-size: 1.5rem;
      margin: 0 10px;
      transition: color 0.3s ease;

      &:hover {
        color: $main-color;
      }
    }
  }

  .legal{
    color: white;
    font-size: 8px;
    text-align: center;
    margin-top: 20px;
  }

}

@media screen and (max-width: 580px) {
  .footer {
    .footer-box {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        padding-bottom: 30px;
      }
    }

    .back-to-top {
      display: flex;
      justify-content: center;
      align-items: center;
      float: none;
      flex-direction: row;

      img {
        padding-bottom: 0px;
      }
    }

    .social-media {
      justify-content: center;
    }
  }
}
