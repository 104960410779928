@import '../../style/App.scss';

.blog {
  padding: 60px 0;
}

@media screen and (max-width: 990px) {
  #blog {
    text-align: center;
  }
}